*[dir=rtl] .pull-right {
    float: left;
}
*[dir=rtl] .pull-left {
    float: right;
}
*[dir=rtl]  .login-area .loginBox .boxTwo .input-group .nav-link {
    left: 36px;
    right: unset;
}
*[dir=rtl] .login-area .loginBox .boxTwo .input-group span {
    right: 36px;
    left: unset;
}
*[dir=rtl] .item-modal .modal-body .input-group span {
    right: 36px;
    left: unset;
}
*[dir=rtl] .header-nav .lang .icon {
    margin-left: 8px;
    margin-right: unset;
}
*[dir=rtl] .sidebar-nav .nav-link img, *[dir=rtl] .sidebar-nav .nav-link svg {
    margin-right: unset;
    margin-left: 10px;
}
*[dir=rtl] .innerMain .dateBox select {
    margin-right: unset;
    margin-left: 15px;
}
*[dir=rtl] .item-modal .modal-body .input-group b {
    left: 25px;
    right: unset;
}
*[dir=rtl] .innerMain .payLinks .linkBtn .icon {
    margin-right: unset;
    margin-left: 10px;
}
*[dir=rtl] .item-modal .modal-body .input-group .btnOne.trans,
*[dir=rtl] .item-modal .modal-footer .btnOne.trans {
    margin-left: 10px;
    margin-right: unset;
}
*[dir=rtl] .innerMain .filterBox .input-group span {
    right: 26px;
    left: unset;
}

*[dir=rtl] .innerMain .mainTable tbody tr .callLink {
    direction: ltr;
}

*[dir=rtl] .innerMain .payLinks .linkBtn {
    margin-left: unset;
    margin-right: 20px;
}

*[dir=rtl] .text-right {
    text-align: left;
}
*[dir=rtl] .innerMain .linkBox a.create .icon {
    margin-left: 10px;
    margin-right: unset;
}