/* comman  font */

:root {
    /* Colors: */
    --unnamed-color-717171: #717171;
    --unnamed-color-ffffff: #ffffff;
    --unnamed-color-5367f9: #5367f9;
    --unnamed-color-000000: #000000;

    /* Font/text values */
    --unnamed-font-family-poppins: Poppins;
    --unnamed-font-family-rubik: Rubik;
    --unnamed-font-style-normal: normal;
    --unnamed-font-weight-normal: normal;
    --unnamed-font-weight-medium: medium;
    --unnamed-font-size-16: 16px;
    --unnamed-font-size-17: 17px;
    --unnamed-font-size-21: 21px;
    --unnamed-font-size-22: 22px;
    --unnamed-character-spacing-0: 0px;
    --unnamed-line-spacing-31: 31px;
    --unnamed-line-spacing-32: 32px;
    --unnamed-line-spacing-38: 38px;
    --unnamed-line-spacing-40: 40px;
}

/* Character Styles */
.unnamed-character-style-1 {
    font-family: var(--unnamed-font-family-rubik);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-medium);
    font-size: var(--unnamed-font-size-22);
    line-height: var(--unnamed-line-spacing-32);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
}
.unnamed-character-style-2 {
    font-family: var(--unnamed-font-family-poppins);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-17);
    line-height: var(--unnamed-line-spacing-40);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
}
.unnamed-character-style-3 {
    font-family: var(--unnamed-font-family-rubik);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-medium);
    font-size: var(--unnamed-font-size-21);
    line-height: var(--unnamed-line-spacing-31);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
}
.unnamed-character-style-4 {
    font-family: var(--unnamed-font-family-poppins);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-16);
    line-height: var(--unnamed-line-spacing-38);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
}

.unnamed-character-style-5 {
    color: var(--unnamed-color-717171);
    text-align: left;
    font: normal normal normal 20px/28px Poppins;
    letter-spacing: 0px;

    opacity: 1;
}

.unnamed-character-style-6 {
    color: var(--unnamed-color-000000);
    text-align: left;
    font: normal normal normal 15px/25px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.ifont {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) 15px/23px var(--unnamed-font-family-poppins);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal medium 15px/23px Poppins;
    letter-spacing: 0px;
    color: #3e3e3e;
    opacity: 1;
}

.inputfiled {
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;

    color: #2c3e50;
    font-size: 15px;
}

.userProfile {
    width: 22rem;
    height: 22rem;
}

.text {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) 20px/34px var(--unnamed-font-family-poppins);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: center;
    font: normal normal medium 20px/34px Poppins;
    letter-spacing: 0px;
    color: #9a9a9a;
    opacity: 1;
}

.rectangle {
    position: relative;
    width: 100%;
    padding: 15px;
    cursor: pointer;
    color: #6f6f6f;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 1;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.rectangle h6 {
    color: #6f6f6f;
}

.rectangle.active {
    background: #007bff;
    background: linear-gradient(270deg, #599DF2 0%, #5367F9 100%);
    color: #ffffff;
}

.rectangle.active h6 {
    color: #ffffff;
}

.datexyz {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #ededed;
    border-radius: 13px;
    opacity: 0.7;
}

.bluebtn {
    background: #5367F9;
    border-radius: 6px;
    opacity: 1;
    border: 1px solid #5367F9;
    color: #ffffff;
    padding: 5px 20px;
}

.bluebtn.green {
    background: #0DDB24;
    border: 1px solid #0DDB24;
}

.bluebtn.red {
    background: #FF091B;
    border: 1px solid #FF091B;
}

.bluebtn.white {
    color: #5367F9;
    background: #ffffff;
}

.bluebtn.orange {
    background: #FF9300;
    border: 1px solid #FF9300;
}

.box123 {
    width: 239px;
    height: 89px;
    box-shadow: 0px 3px 16px #00000017;
    border: 1px solid #5367f9;
    border-radius: 12px;

    text-align: left;
}

.boxButton123 {
    border: 1px solid #ededed;
    border-radius: 13px;
}

.boxActiv123 {
    background: #00cc6e 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 12px;
    width: 73px;
    font: normal normal 600 14px/26px Poppins;
    letter-spacing: 0px;
    color: #ffffff;
}

.boxmain {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #ededed;
    border-radius: 12px;
    opacity: 1;
}

.redButton {
    background: #eb003f 0% 0% no-repeat padding-box;
    border-radius: 13px;
    color: #ffffff;
}
.BorderButton {
    border: 1px solid var(--unnamed-color-5367f9);
    width: 73px;
    border-radius: 12px;
    font: normal normal 600 14px/26px Poppins;
    letter-spacing: 0px;
    color: var(--unnamed-color-5367f9);
}

.redButtonBorder {
    border: 1px solid #eb003f;
    border-radius: 10px;
    background-color: #eb003f6e;
    color: #eb003f;
}

.greenButtonBorder {
    border: 1px solid #00cc6e;
    border-radius: 10px;
    background-color: #00cc6d70;
    color: #00cc6e;
}

.inputType {
    width: 72px;
    border: 1px solid white;
    border-radius: 2px;
}

.checkBox {
    border: 1px solid white;
}

.btnxt {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #5367f9;
    width: 177.79px;
    height: 55px;
    border-radius: 7px;
}

.sticky-col {
    position: absolute;
    right: 5px;
    top: 23px;
    background: #f7f7f7;
    z-index: 1;
    text-align: center;

    overflow: hidden;
}

.sticky-Row {
    position: absolute;
    right: 5px;

    background: #f7f7f7;
    z-index: 1;
    text-align: center;

    overflow: hidden;
}

.Actiondegin {
    width: 50px;
    height: 50px;
    text-align: right;
    padding-top: 12px;
    background: white;
}

.Cardgraph {
    width: 250px;
    height: 150;
    background: #fdfdfd 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #5367f91a;
    border-radius: 15px;
    opacity: 0.7;
}

.box123xyz {
    width: 239px;
    height: 89px;

    border: none;
    border-radius: 12px;
    text-align: left;
}

.graphbox {
    background: #fdfdfd 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #5367f91a;
    border-radius: 15px;
}

.box33 {
    width: 154px;
    height: 72px;

    cursor: pointer;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 0.7;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.box33.active {
    background-color: #5367F9;
    color: #fff;
    border-color: #5367f9;
}


.dataBox{
border: 1px solid #EDEDED;
border-radius: 16px;
width: 162px;
height: 46px;
}

.allbtn{
    border: 1px solid var(--unnamed-color-5367f9);
border: 1px solid #5367F9;
border-radius: 14px;
color: var(--unnamed-color-5367f9);
}