// Here you can add other styles
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #000000;
}

a {
    text-decoration: none;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    background: #4580c2;
    border-radius: 10px;
}

::selection {
    background: #4580c2;
    color: #ffffff;
}

::-moz-selection {
    background: #4580c2;
    color: #ffffff;
}

.sidebar {
    background: #ffffff;
}

.sidebar-brand {
    background: transparent;
    padding-top: 30px;
}

.sidebar-brand b {
    color: #000000;
    margin-left: 10px;
}

.sidebar-user {
    display: block;
    padding: 10px 15px !important;
    margin: 30px 15px 0px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.sidebar-user li {
    margin: 0px !important;
}

.sidebar-user .user-text {
    position: relative;
}

.sidebar-user .user-text::before {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    right: -10px;
    /* transform: translateY(-50%); */
    background-image: url(../images/down-arrow-icon.png);
}

.sidebar-user .user-text h6 {
    color: #000000;
    font-size: 15px;
    margin: 0px;
}

.sidebar-user .user-text span {
    color: #a1a1a1;
    font-size: 14px;
}

.sidebar-user .user-drop {
    width: 100%;
    top: 5px !important;
}

.sidebar-nav {
    margin: 30px 0px 20px 0px;
}

.sidebar-nav::-webkit-scrollbar-track {
    background-color: #ffffff;
}

.sidebar-nav::-webkit-scrollbar {
    width: 3px;
    background-color: #ffffff;
}

.sidebar-nav::-webkit-scrollbar-thumb {
    background-color: #ffffff;
}

.sidebar-nav .nav-item {
    margin-bottom: 5px;
}

.sidebar-nav .nav-item:last-child {
    margin-bottom: 0px;
}

.sidebar-nav .nav-link {
    position: relative;
    color: #000000;
    padding: 15px 20px 15px 30px;
    border-radius: 0px 15px 15px 0px;
    border-left: 5px solid #ffffff;
}

.sidebar-nav .nav-link:hover {
    color: #000000;
    background: transparent;
}

.sidebar-nav .nav-link img,
.sidebar-nav .nav-link svg {
    margin-right: 20px;
}

.sidebar-nav .nav-link.active {
    background: #eceefe;
    border-left: 5px solid #5367f9;
    color: #000000;
}

.sidebar-nav .nav-group-toggle::after {
    background-image: url(../images/down-arrow-icon.png);
    flex: 0 1 16px;
    height: 16px;
}

.sidebar-nav .nav-link:hover.nav-group-toggle::after {
    background-image: url(../images/down-arrow-icon.png);
}

.sidebar-nav .nav-group.show {
    background: #ffffff;
}

.sidebar-nav .nav-group.show .nav-group-toggle {
    background: #eceefe;
    border-left: 5px solid #5367f9;
    color: #000000;
}

.sidebar-nav .nav-group-items .nav-item {
    margin-bottom: 0px;
}

.sidebar-nav .nav-group-items .nav-link.active {
    background: transparent;
    border-color: transparent;
    color: #5367f9;
}

.sidebar-nav .nav-group-items .nav-link img {
    margin-right: 15px;
}

.sidebar-nav .nav-group-items .nav-link.active img {
    filter: invert(47%) sepia(95%) saturate(4744%) hue-rotate(224deg) brightness(100%) contrast(96%);
}

.sidebar-nav.settNav {
    position: relative;
    margin: 30px 0px 30px 10px;
}

.sidebar-nav.settNav .nav-link {
    font-size: 15px;
    padding: 10px 15px;
}

.sidebar-nav.settNav .nav-link img {
    width: 18px;
    margin-right: 8px;
    filter: brightness(0) invert(1);
}

.sidebar-nav.settNav .nav-link.active img {
    filter: invert(52%) sepia(87%) saturate(837%) hue-rotate(189deg) brightness(97%) contrast(94%);
    -webkit-filter: invert(52%) sepia(87%) saturate(837%) hue-rotate(189deg) brightness(97%) contrast(94%);
}

.sidebar-nav.settNav .nav-link img.profile {
    filter: none;
}

.sidebar-nav.settNav .nav-link .badge {
    display: none;
}

.sidebar .help {
    background: #4580c2;
    color: #ffffff;
    padding: 8px;
    margin: 0px 25px;
    text-align: center;
    border-radius: 10px;
    text-decoration: none;
}

.sidebar .help img {
    display: block;
    margin: 0 auto;
}

.sidebar .help span {
    display: block;
    font-size: 10px;
}

.sidebar .support {
    background: #4580c2;
    color: #ffffff;
    padding: 8px;
    margin: 10px 25px;
    text-align: center;
    border-radius: 10px;
    text-decoration: none;
}

.sidebar .support img {
    margin-right: 10px;
}

.header {
    padding: 15px 10px;
    border: 0;
    margin-bottom: 40px;
    background: #fafafa;
}

.header .input-group {
    position: relative;
    width: 30%;
    margin-left: 10px;
}

.header .input-group img {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    z-index: 9;
}

.header .search {
    border: 0;
    border-radius: 0;
    background: #ffffff;
    padding: 10px 10px 10px 45px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
}

.header .search:focus {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
}

.header .search::placeholder {
    font-size: 14px;
    color: #bababa;
}

.header-nav {
    align-items: center;
}

.header-nav .nav-item {
    margin: 0px 10px;
}

.header-nav .nav-item:last-child {
    margin-right: 0px;
}

.header-nav .nav-item .top-link {
    display: flex;
    align-items: center;
    padding: 8px 15px;
    border: 1px solid #c8c8c8;
    border-radius: 10px;
}

.header-nav .nav-item .top-link img {
    margin-right: 10px;
}

.header-nav .nav-item .top-link b {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
}

.header-nav .lang {
    display: flex;
    align-items: center;
    margin: 0;
    margin-right: 40px;
}

.header-nav .lang .icon {
    margin-right: 8px;
}

.breadcrumb {
    margin: 0;
}

.breadcrumb-item,
.breadcrumb-item::before {
    display: none;
}

.breadcrumb-item.active {
    display: block;
    color: #1c1c1c;
    font-size: 32px;
    line-height: 32px;
    font-weight: 700;
    padding: 0px !important;
}

.login-area {
    background: url(../images/login-bg.jpg) no-repeat center center;
    background-size: cover;
    display: flex;
    align-items: center;
    flex-direction: row;
    min-height: 100vh;
}

.login-area .loginBox {
    display: flex;
    align-items: center;
}

.login-area .loginBox .boxOne {
    flex: 1 0;
    background: url(../images/login-bg1.jpg) no-repeat center center;
    background-size: cover;
    padding: 60px;
    border-radius: 6px;
    box-shadow: 10px 10px 40px 0px rgba(85, 206, 186, 0.4);
    z-index: 2;
}

.login-area .loginBox .boxOne h1 {
    color: #abe0e5;
    font-size: 46px;
    font-weight: 100;
    margin: 60px 0px 10px;
}

.login-area .loginBox .boxOne h2 {
    color: #ffffff;
    font-size: 46px;
    font-weight: 800;
    margin: 0px;
}

.login-area .loginBox .boxOne p {
    color: #abe0e5;
    margin: 30px 0px 20px;
}

.login-area .loginBox .boxOne .moreBtn {
    background: transparent;
    font-size: 14px;
    color: #ffffff;
    padding: 8px 15px;
    border: 1px solid #ffffff;
    border-radius: 6px;
    text-decoration: none;
    display: inline-block;
}

.login-area .loginBox .boxOne .moreBtn:hover {
    background: #ffffff;
    color: #56c6c4;
}

.login-area .loginBox .boxOne .regImg {
    width: 150px;
    margin-bottom: 30px;
}

.login-area .loginBox .boxOne .innerBox {
    display: flex;
    margin-top: 30px;
}

.login-area .loginBox .boxOne .innerBox .iconBox {
    flex: 1 0 17.16%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    border-radius: 50%;
    margin-right: 20px;
    background: #ffffff;
}

.login-area .loginBox .boxOne .innerBox .textBox h6 {
    color: #ffffff;
    margin-bottom: 12px;
}

.login-area .loginBox .boxOne .innerBox .textBox p {
    color: #ffffff;
    margin: 0px;
    font-size: 14px;
}

.login-area .loginBox .boxTwo {
    flex: 1 0;
    background: #ffffff;
    padding: 80px 70px 80px 120px;
    margin-left: -50px;
    border-radius: 6px;
    box-shadow: 10px 10px 40px 0px rgba(89, 157, 242, 0.4);
    z-index: 1;
}

.login-area .loginBox .boxTwo h1 {
    color: #599df2;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 20px;
}

.login-area .loginBox .boxTwo p {
    color: #a6a6a6;
    margin-bottom: 30px;
}

.login-area .loginBox .boxTwo h4 {
    color: #313131;
    font-weight: 700;
    margin-bottom: 30px;
}

.login-area .loginBox .boxTwo .input-group {
    margin-bottom: 20px;
}

.login-area .loginBox .boxTwo .input-group input,
.login-area .loginBox .boxTwo .input-group select {
    border: 0;
    border-radius: 0;
    color: #707070;
    background: #eff6f9;
    padding: 40px 30px 15px 30px;
    border-left: 5px solid #eff6f9;
}

.login-area .loginBox .boxTwo .input-group input:focus,
.login-area .loginBox .boxTwo .input-group input:valid,
.login-area .loginBox .boxTwo .input-group select:focus,
.login-area .loginBox .boxTwo .input-group select:valid {
    background: #ffffff;
    border-left: 5px solid #4580c2;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
}

.login-area .loginBox .boxTwo .input-group span {
    position: absolute;
    top: 50%;
    left: 36px;
    font-size: 12px;
    color: #707070;
    text-transform: uppercase;
    transform: translateY(-50%);
    z-index: 9;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.login-area .loginBox .boxTwo .input-group input:focus~span,
.login-area .loginBox .boxTwo .input-group input:valid~span,
.login-area .loginBox .boxTwo .input-group select:focus~span,
.login-area .loginBox .boxTwo .input-group select:valid~span {
    top: 20%;
    transform: none;
    color: #a6a6a6;
}

.login-area .loginBox .boxTwo .input-group .nav-link {
    position: absolute;
    top: 50%;
    right: 36px;
    transform: translateY(-50%);
    font-size: 12px;
    font-weight: 500;
    color: #599df2;
    text-transform: uppercase;
    z-index: 9;
}

.login-area .loginBox .boxTwo .wApp {
    color: #313131;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.login-area .loginBox .boxTwo .wApp input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    border: none;
    box-shadow: none;
    margin: 0;
    margin-right: 10px;
}

.login-area .loginBox .boxTwo .wApp input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    border: 2px solid #313131;
    border-radius: 3px;
    background-color: #ffffff;
}

.login-area .loginBox .boxTwo .wApp input[type='checkbox']:checked:before {
    border-color: #599df2;
    background-color: #599df2;
}

.login-area .loginBox .boxTwo .wApp input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 4px;
    height: 8px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 3px;
    left: 6px;
}

.login-area .loginBox .boxTwo .loginBtn {
    width: 100%;
    color: #ffffff;
    border: 0;
    border-radius: 0;
    padding: 20px 10px;
    margin: 20px 0px 35px;
    font-weight: 700;
    text-transform: uppercase;
    background: rgb(89, 157, 242);
    background: linear-gradient(270deg, rgba(89, 157, 242, 1) 0%, rgba(85, 206, 186, 1) 100%);
}

.login-area .loginBox .boxTwo .signupBox {
    color: #707070;
    font-weight: 500;
    text-align: center;
}

.login-area .loginBox .boxTwo .signupBox .nav-link {
    display: inline;
    color: #599df2;
    text-decoration: none;
}

.innerMain .dateBox input {
    border: 1px solid #bfc5d0;
    border-radius: 6px !important;
    font-size: 15px;
    padding: 3px;
    outline: none;
}

.innerMain .dateBox select {
    border: 1px solid #bfc5d0;
    border-radius: 6px !important;
    font-size: 15px;
    padding: 3px;
    outline: none;
}

.innerMain .dateBox input:focus,
.innerMain .dateBox select:focus {
    box-shadow: none;
}

.innerMain .dateBox h6 {
    color: #599df2;
    font-size: 32px;
    line-height: 32px;
    font-weight: 100;
    margin: 0px 10px !important;
}

.innerMain .dateBox .nav-link {
    border: 1px solid #bfc5d0;
    border-radius: 6px !important;
    margin: 0px !important;
    padding: 4px 15px;
    height: 38px;
}

.innerMain .dateBox .nav-link.process {
    color: #1c1c1c;
    font-size: 14px;
    padding: 4px 6px;
    cursor: pointer;
}

.innerMain .dateBox .nav-link.process img {
    margin-right: 10px;
}

.innerMain .payLinks {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.innerMain .payLinks .linkBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1c1c1c;
    font-size: 14px;
    padding: 8px 15px;
    margin-left: 20px;
    border: 1px solid #bfc5d0;
    border-radius: 6px !important;
    cursor: pointer;
}

.innerMain .payLinks .linkBtn:first-child {
    margin-left: 0px;
}

.innerMain .payLinks .linkBtn .icon {
    color: #599df2;
    height: 22px;
    width: 22px;
    padding: 2px;
    margin-right: 10px;
}

.innerMain .payLinks .linkBtn img {
    width: 18px;
    margin-right: 10px;
    filter: brightness(0) invert(1);
}

.innerMain .payLinks .linkBtn.fill {
    color: #ffffff;
    background: #599df2;
    border-color: #599df2;
}

.innerMain .payLinks .linkBtn.fill .icon {
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 50%;
}

.innerMain .payLinks .filterBox {
    position: relative;
    margin-left: 20px;
    margin-top: -22px;
}

.innerMain .payLinks .filterBox span {
    color: #797979;
    font-size: 14px;
}

.innerMain .payLinks .filterBox select {
    border: 1px solid #bfc5d0;
    border-radius: 6px !important;
    padding-left: 45px;
    color: #1c1c1c;
}

.innerMain .payLinks .filterBox select:focus {
    box-shadow: none;
}

.innerMain .payLinks .filterBox .icon {
    position: absolute;
    top: 34px;
    left: 14px;
    color: #599df2;
    width: 20px;
    height: 20px;
}

.innerMain .boxOne {
    border: 0;
    border-radius: 10px;
    padding: 0px;
    margin-bottom: 15px;
    color: #000000;
    background: #ffffff;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

.innerMain .boxOne .card-body {
    padding: 0;
}

.innerMain .boxOne .title {
    font-size: 14px;
    color: #A2A2A2;
    padding: 10px 15px 0px;
}

.innerMain .usersBlock small {
    color: #717171;
}

.innerMain .usersBlock h4 {
    color: #000000;
    font-weight: 700;
    margin-bottom: 5px;
}

.innerMain .usersBlock .bottom {
    display: flex;
    align-items: center;
    gap: 6px;
    color: #000000;
    font-weight: 500;
}

.innerMain .usersBlock .bottom i {
    font-size: 24px;
    display: contents;
    color: #5AAE25;
}

.innerMain .usersBlock .bottom i.down,
.innerMain .usersBlock .bottom span.down {
    color: #FF0000;
}

.innerMain .usersBlock .bottom span {
    color: #5AAE25;
}

.innerMain .graphBox {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #ebebeb;
}

.innerMain .graphBox h4 {
    color: #000000;
    margin-bottom: 0px;
}

.innerMain .graphBox small {
    color: #5367F9;
    margin: 5px 0px;
    display: block;
}

.innerMain .graphBox .bottom {
    display: flex;
    align-items: center;
    gap: 6px;
    color: #000000;
    font-size: 16px;
    font-weight: 500;
}

.innerMain .graphBox .bottom i {
    font-size: 24px;
    display: contents;
    color: #5AAE25;
}

.innerMain .graphBox .bottom i.down,
.innerMain .graphBox .bottom span.down {
    color: #FF0000;
}

.innerMain .graphBox .bottom span {
    color: #5AAE25;
}

.innerMain .boxShadow {
    border: 0px;
    border-radius: 0px;
    padding: 20px 20px 25px;
    margin-bottom: 30px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
}

.innerMain .titleBox i {
    background: #4580c2;
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-right: 15px;
}

.innerMain .titleBox h4 {
    color: #1c1c1c;
    margin: 0;
}

.innerMain .titleBox h5 {
    color: #1c1c1c;
    margin: 0;
    margin-top: 20px;
}

.innerMain .titleBox h5 span {
    color: #9a9a9a;
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
}

.innerMain .chartSale {
    width: 860px;
    height: 400px;
}

.innerMain .chartInsight {
    height: 381px;
    margin: 0 auto;
}

.innerMain .chartTraffic {
    height: 416px;
    margin: 0 auto;
}

.innerMain .mainTable {
    margin-bottom: 0;
    border: none;
    border-color: transparent;
    text-align: left;
    white-space: nowrap;
}

.innerMain .mainTable.table-bordered {
    border-color: gainsboro;
}

.innerMain .mainTable thead {
    color: #000000;
    font-size: 14px;
    text-transform: uppercase;
    background: #F5F5F5;
    vertical-align: top;
}

.innerMain .mainTable thead th {
    padding: 15px;
    background: inherit;
    color: inherit;
    font-weight: 600;
    text-transform: capitalize;
}

.innerMain .mainTable thead th small {
    display: block;
    color: #6d6d6d;
}

.innerMain .mainTable thead th b {
    font-weight: 700;
}

.innerMain .mainTable tbody th {
    padding: 12px;
    background: inherit;
    font-weight: 600;
}

.innerMain .mainTable.strip tbody tr:nth-of-type(even) {
    background: #F5F5F5;
}

.innerMain .mainTable tbody td {
    padding: 12px;
    background: inherit;
    color: #000000;
}

.innerMain .mainTable tbody td b {
    display: block;
    color: #000000;
    font-weight: 500;
}

.innerMain .mainTable tbody td b.red {
    color: #FF0000;
}

.innerMain .mainTable tbody td small {
    display: block;
    color: #6d6d6d;
}

.innerMain .mainTable tbody td span {
    display: flex;
    align-items: center;
}

.innerMain .mainTable tbody td span i {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    margin-right: 8px;
    background: #B9B9B9;
}

.innerMain .mainTable tbody td span i.active {
    background: #00E572;
}

.innerMain .mainTable tbody td span i.pending {
    background: #FFC400;
}

.innerMain .mainTable tbody td .severity {
    display: flex;
    align-items: center;
    gap: 5px;
}

.innerMain .mainTable tbody td .severity span {
    width: 15px;
    height: 8px;
    border-radius: 5px;
    background: #FF0000;
}

.innerMain .mainTable tbody td .severity span.orange {
    background: #FF7F00;
}

.innerMain .mainTable tbody td .severity span.gray {
    background: #C3C3C3;
}

.innerMain .mainTable tbody td .verify {
    display: block;
    background: #e8ebff;
    border: 1px solid #5367f9;
    color: #5367f9;
    padding: 5px 15px;
    border-radius: 30px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
}

.innerMain .mainTable tbody td .verify.unv {
    background: #f88b8b;
    border: 1px solid #8b0000;
    color: #8b0000;
}

.innerMain .mainTable tbody td .verify.activ {
    background: #e3fff3;
    border: 1px solid #00c177;
    color: #00c177;
}

.innerMain .mainTable tbody td .verify.block {
    background: #ffe8e8;
    border: 1px solid #d34a4b;
    color: #d34a4b;
}

.innerMain .mainTable tbody td .verify.unmatch {
    background: #fff4e5;
    border: 1px solid #de8000;
    color: #de8000;
}

.innerMain .mainTable tbody td .verify.green {
    background: #0DDB24;
    border: 1px solid #0DDB24;
    color: #ffffff;
}

.innerMain .mainTable tbody td .verify.red {
    background: #FF091B;
    border: 1px solid #FF091B;
    color: #ffffff;
}

.innerMain .mainTable tbody td .report {
    display: flex;
    gap: 8px;
}

.innerMain .mainTable tbody td .report span {
    background: #ffe8e8;
    border: 1px solid #d34a4b;
    color: #d34a4b;
    font-size: 12px;
    padding: 2px 12px;
    border-radius: 8px;
    text-align: center;
}

.innerMain .mainTable tbody td .report span.safe {
    background: #e3fff3;
    border: 1px solid #00c177;
    color: #00c177;
}

.innerMain .mainTable tbody td .report span.white {
    background: #ffffff;
    border: 1px solid #E5E5E5;
    color: #5367F9;
}

.innerMain .mainTable tbody tr:nth-of-type(even) {
    background: #ffffff;
}

.innerMain .mainTable tbody tr .status {
    display: block;
    color: #ffffff;
    font-size: 14px;
    padding: 6px 12px;
    border-radius: 8px;
    background: #599df2;
}

.innerMain .mainTable tbody tr .status.success {
    background: #55ce96;
}

.innerMain .mainTable tbody tr .status.fail {
    background: #ff1414;
}

.innerMain .mainTable tbody tr .status.complete {
    background: #c6c6c6;
}

.innerMain .mainTable tbody tr .status.edit {
    color: #0071ff;
    background: #e5f1ff;
    border: 1px solid #0071ff;
    cursor: pointer;
}

.innerMain .mainTable tbody tr .callLink {
    display: flex;
    align-items: center;
    color: #09c1a3;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 18px;
    margin-bottom: 5px;
    border-radius: 8px;
    background: #eafffb;
    max-width: max-content;
}

.innerMain .mainTable tbody tr .callLink.mail {
    color: #0071ff;
    background: #edf5ff;
}

.innerMain .mainTable tbody tr .callLink img {
    margin-right: 10px;
}

.innerMain .mainTable tbody tr .delLink {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff0000;
    font-size: 14px;
    font-weight: 500;
}

.innerMain .mainTable tbody tr .delLink img {
    margin-right: 10px;
}

.innerMain .linkBox {
    margin-bottom: 20px;
}

.innerMain .linkBox a {
    display: inline-block;
    color: #1c1c1c;
    border-radius: 6px;
    text-decoration: none;
    font-weight: 500;
    padding: 12px 40px;
    margin-right: 10px;
    background: transparent;
}

.innerMain .linkBox a:last-child {
    margin-right: 0px;
}

.innerMain .linkBox a:hover,
.innerMain .linkBox a.active {
    color: #ffffff;
    background: linear-gradient(270deg, rgba(89, 157, 242, 1) 0%, rgba(85, 206, 186, 1) 100%);
}

.innerMain .linkBox a.create {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 400;
    margin: 0;
    padding: 12px 30px;
    background: #599df2;
    border-radius: 6px;
    cursor: pointer;
}

.innerMain .linkBox a.create:hover {
    background: #4580c2;
}

.innerMain .linkBox a.create .icon {
    height: 25px;
    width: 25px;
    padding: 3px;
    margin-right: 10px;
    border: 1px solid #ffffff;
    border-radius: 50%;
}

.innerMain .filterBox {
    margin-bottom: 0px;
}

.innerMain .filterBox h5 {
    color: #1c1c1c;
    margin: 0;
}

.innerMain .filterBox .input-group {
    margin-top: 25px;
}

.innerMain .filterBox .input-group input,
.innerMain .filterBox .input-group select {
    border: 0;
    border-radius: 0;
    color: #313131;
    padding: 35px 25px 10px 25px;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
}

.innerMain .filterBox .input-group span {
    position: absolute;
    top: 15%;
    left: 26px;
    font-size: 12px;
    color: #a6a6a6;
    text-transform: uppercase;
    z-index: 9;
}

.innerMain .filterBox .input-group b {
    position: absolute;
    top: 50%;
    right: 25px;
    font-size: 14px;
    font-weight: 500;
    color: #313131;
    transform: translateY(-50%);
    z-index: 9;
}

.innerMain .filterBox .input-group .divBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 22px;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
}

.innerMain .filterBox .input-group .divBox h5 {
    color: #1c1c1c;
    font-size: 18px;
    margin: 0;
}

.innerMain .filterBox .input-group .divBox h6 {
    color: #1c1c1c;
    margin: 0;
}

.innerMain .filterBox .input-group .divBox .textBox span {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    color: #b0b0b0;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.innerMain .filterBox .input-group .divBox .textBox h4 {
    color: #1c1c1c;
    font-weight: 700;
    margin-bottom: 15px;
}

.innerMain .filterBox .input-group .divBox .textBox h4 small {
    font-size: 14px;
    font-weight: 400;
    margin-right: 5px;
}

.innerMain .filterBox .input-group .divBox .textBox p {
    margin-bottom: 0;
}

.innerMain .filterBox .input-group .divBox .btnBox {
    margin: 0;
    flex: 1 0 22%;
    justify-content: flex-end;
    margin-left: 30px;
}

.innerMain .filterBox .input-group .divBox .btnBox .searchBtn {
    font-size: 14px;
    padding: 10px 30px;
    width: auto;
}

.innerMain .filterBox .input-group .checkBox .switch {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 45px;
    margin: 0px;
}

.innerMain .filterBox .input-group .checkBox .switch input {
    display: none;
}

.innerMain .filterBox .input-group .checkBox .switch .slider {
    background-color: #f2f2f2;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.innerMain .filterBox .input-group .checkBox .switch .slider:before {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 2px;
    height: 16px;
    width: 16px;
    background: #ffffff;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.innerMain .filterBox .input-group .checkBox .switch input:checked+.slider {
    background: linear-gradient(270deg, rgba(89, 157, 242, 1) 0%, rgba(85, 206, 186, 1) 100%);
}

.innerMain .filterBox .input-group .checkBox .switch input:checked+.slider:before {
    transform: translateX(25px);
}

.innerMain .filterBox .input-group .checkBox .switch .slider.round {
    border-radius: 35px;
}

.innerMain .filterBox .input-group .checkBox .switch .slider.round:before {
    border-radius: 50%;
}

.innerMain .filterBox .btnBox {
    display: flex;
    align-items: center;
    margin-top: 25px;
}

.innerMain .filterBox .btnBox .searchBtn {
    width: 100%;
    color: #ffffff;
    border: 0;
    border-radius: 6px;
    padding: 22px 10px;
    font-weight: 500;
    background: linear-gradient(270deg, rgba(89, 157, 242, 1) 0%, rgba(85, 206, 186, 1) 100%);
}

.innerMain .filterBox .btnBox .searchBtn.clear {
    color: #599df2;
    background: #f4f4f4;
}

.innerMain .invoiceBlock .invoiceHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.innerMain .invoiceBlock .invoiceHead .headLeft {
    display: flex;
    align-items: center;
}

.innerMain .invoiceBlock .invoiceHead .headLeft .imgBox {
    padding: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.innerMain .invoiceBlock .invoiceHead .headLeft .textBox {
    margin-left: 30px;
}

.innerMain .invoiceBlock .invoiceHead .headLeft .textBox h5 {
    color: #313131;
    margin-bottom: 12px;
}

.innerMain .invoiceBlock .invoiceHead .headLeft .textBox h6 {
    color: #313131;
    margin: 0;
}

.innerMain .invoiceBlock .invoiceHead .headLeft .textBox h6 span {
    color: #bababa;
    margin-right: 5px;
}

.innerMain .invoiceBlock .invoiceHead .headRight img {
    width: 130px;
}

.innerMain .invoiceBlock .invoiceHead .headRight .textBox {
    color: #313131;
    font-size: 12px;
    text-align: center;
    margin-top: 5px;
}

.innerMain .invoiceBlock .invoiceHead .headRight .textBox span {
    display: block;
}

.innerMain .invoiceBlock .invoiceHead .headRight .textBox span b {
    color: #599df2;
    font-weight: 400;
}

.innerMain .invoiceBlock .input-group {
    margin-top: 50px;
}

.innerMain .invoiceBlock .input-group input,
.innerMain .invoiceBlock .input-group select,
.innerMain .invoiceBlock .input-group textarea {
    color: #313131;
    padding: 35px 0px 10px;
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid #f4f4f4;
    background-color: #ffffff;
    box-shadow: none;
    resize: none;
}

.innerMain .invoiceBlock .input-group span {
    position: absolute;
    top: 0;
    left: 0;
    color: #313131;
    font-size: 18px;
    font-weight: 500;
    z-index: 9;
}

.innerMain .invoiceBlock .mainTable {
    margin-top: 60px;
}

.innerMain .invoiceBlock .mainTable .input-group {
    margin: 0;
}

.innerMain .invoiceBlock .mainTable .input-group input,
.innerMain .invoiceBlock .mainTable .input-group select {
    padding: 10px 0px 10px;
    background: transparent;
}

.innerMain .invoiceBlock .mainTable .addBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border: 0;
    border-radius: 6px;
    padding: 10px 30px;
    margin: 0 auto;
    font-weight: 500;
    background: linear-gradient(270deg, rgba(89, 157, 242, 1) 0%, rgba(85, 206, 186, 1) 100%);
}

.innerMain .invoiceBlock .mainTable .addBtn .icon {
    height: 20px;
    width: 20px;
    padding: 2px;
    margin-right: 12px;
    border: 1px solid #ffffff;
    border-radius: 50%;
}

.innerMain .invoiceBlock .invoiceFoot {
    background: #f7faff;
    text-align: center;
    padding: 10px;
    margin-top: 50px;
}

.innerMain .invoiceBlock .invoiceFoot h5 {
    color: #313131;
    font-size: 18px;
    line-height: 18px;
}

.innerMain .invoiceBlock .invoiceFoot p {
    color: #b3bbc7;
    font-size: 14px;
    line-height: 14px;
    margin: 0px;
}

.innerMain .invoiceSideblock .mainBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border: 0;
    border-radius: 6px;
    padding: 12px 30px;
    margin-bottom: 30px;
    font-weight: 500;
    width: 100%;
    background: linear-gradient(270deg, rgba(89, 157, 242, 1) 0%, rgba(85, 206, 186, 1) 100%);
}

.innerMain .invoiceSideblock .mainBtn img {
    margin-right: 12px;
}

.innerMain .invoiceSideblock .mainBtn.save {
    background: #599df2;
}

.innerMain .invoiceSideblock .mainBtn.delete {
    color: #313131;
    background: #f4f4f4;
}

.innerMain .invoiceSideblock .settingBox {
    margin-top: 30px;
}

.innerMain .invoiceSideblock .settingBox h6 {
    color: #313131;
}

.innerMain .invoiceSideblock .settingBox .setBox {
    position: relative;
    color: #313131;
    padding: 15px 25px;
    margin-top: 25px;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
}

.innerMain .invoiceSideblock .settingBox .setBox span {
    display: block;
    color: #a6a6a6;
    font-size: 14px;
}

.innerMain .invoiceSideblock .settingBox .setBox select {
    padding: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
}

.innerMain .invoiceSideblock .settingBox .setBox input[type='checkbox'] {
    position: absolute;
    top: 50%;
    right: 25px;
    cursor: pointer;
    border: none;
    box-shadow: none;
    margin: 0;
    transform: translateY(-50%);
}

.innerMain .invoiceSideblock .settingBox .setBox input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    border: 2px solid #313131;
    border-radius: 3px;
    background-color: #ffffff;
}

.innerMain .invoiceSideblock .settingBox .setBox input[type='checkbox']:checked:before {
    border-color: #599df2;
    background-color: #599df2;
}

.innerMain .invoiceSideblock .settingBox .setBox input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 4px;
    height: 8px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 3px;
    left: 6px;
}

.innerMain .reportBlock {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 30px 30px 0px;
    margin: 0px 5px 30px 0px;
    border-right: 2px solid #f4f4f4;
}

.innerMain .reportBlock.last {
    border-right: 0px;
}

.innerMain .reportBlock h5 {
    position: relative;
    color: #313131;
    padding: 15px 40px;
    margin-bottom: 40px;
    text-transform: uppercase;
    width: 100%;
}

.innerMain .reportBlock h5::before {
    content: '';
    display: block;
    position: absolute;
    width: 12px;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50px;
    background: linear-gradient(180deg, rgba(89, 157, 242, 1) 0%, rgba(85, 206, 186, 1) 100%);
}

.innerMain .reportBlock .reportBox {
    flex: 1;
    padding: 20px;
    margin-right: 30px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
}

.innerMain .reportBlock .reportBox:last-child {
    margin-right: 0px;
}

.innerMain .reportBlock .reportBox h6 {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 25px;
    border-bottom: 2px solid #f4f4f4;
}

.innerMain .reportBlock .reportBox img {
    margin-right: 10px;
}

.innerMain .reportBlock .reportBox p {
    color: #000000;
    font-size: 15px;
    margin: 0;
}

.innerMain .reportBlock .reportBox .downloadBtn {
    display: flex;
    align-items: center;
    color: #ffffff;
    font-size: 15px;
    border: 0;
    border-radius: 6px;
    padding: 10px 30px;
    margin: 0 auto;
    margin-top: 30px;
    background: linear-gradient(270deg, rgba(89, 157, 242, 1) 0%, rgba(85, 206, 186, 1) 100%);
}

.innerMain .reportBlock .reportBox .downloadBtn img {
    width: 16px;
    filter: brightness(0) invert(1);
}

.innerMain .settingSideblock .sidebar-nav {
    margin: 0px;
}

.innerMain .settingSideblock .sidebar-nav .nav-item:first-child {
    border-bottom: 2px solid #f4f4f4;
    padding-bottom: 10px;
}

.innerMain .settingSideblock .sidebar-nav .nav-link {
    color: #313131;
    border-radius: 6px;
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 500;
    background: #ffffff;
}

.innerMain .settingSideblock .sidebar-nav .nav-link img {
    margin-right: 15px;
}

.innerMain .settingSideblock .sidebar-nav .nav-link.active {
    color: #ffffff;
    background: linear-gradient(270deg, rgba(89, 157, 242, 1) 0%, rgba(85, 206, 186, 1) 100%);
}

.innerMain .settingSideblock .sidebar-nav .nav-link.active img.profile {
    filter: none;
}

.innerMain .settingSideblock .sidebar-nav .nav-link.active img {
    filter: brightness(0) invert(1);
}

.innerMain .settingSideblock .sidebar-nav .nav-link.active .badge {
    color: #ffffff;
}

.innerMain .settingSideblock .sidebar-nav .nav-link .badge {
    display: block;
    color: #a0a0a0;
    width: 100%;
    text-align: left;
    padding: 0;
    margin-top: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    white-space: normal;
}

.innerMain .settingBlock .profileBox {
    display: flex;
    align-items: center;
}

.innerMain .settingBlock .profileBox .textBox {
    margin-left: 20px;
}

.innerMain .settingBlock .profileBox .textBox .nameBox h5 {
    color: #000000;
    margin: 0;
}

.innerMain .settingBlock .profileBox .textBox .nameBox h5 span {
    color: #4580c2;
    font-size: 16px;
    font-weight: 400;
    margin-left: 40px;
}

.innerMain .settingBlock .profileBox .textBox .idBox {
    position: relative;
    padding: 10px;
    margin-top: 15px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
}

.innerMain .settingBlock .profileBox .textBox .idBox span {
    color: #c4c4c4;
    font-size: 12px;
    line-height: 12px;
    display: block;
}

.innerMain .settingBlock .profileBox .textBox .idBox h6 {
    color: #313131;
    font-size: 15px;
    margin: 0;
    margin-top: 8px;
}

.innerMain .settingBlock .profileBox .textBox .idBox .nav-link {
    position: absolute;
    top: 50%;
    right: 10px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    color: #4580c2;
    transform: translateY(-50%);
}

.innerMain hr {
    margin: 30px 0px;
    border: 0px;
    border-top: 1px solid #f4f4f4;
    opacity: 1;
}

.innerMain .newBox {
    display: flex;
    align-items: center;
}

.innerMain .newBox img {
    margin-right: 20px;
}

.innerMain .newBox h5 {
    color: #000000;
    margin: 0px;
}

.innerMain .newBox b {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
}

.process-modal .modal-dialog {
    max-width: 600px;
}

.process-modal .modal-content {
    border: 0;
}

.process-modal .modal-header {
    background: #599df2;
    color: #ffffff;
    justify-content: center;
    border: 0;
}

.process-modal .modal-header .modal-title {
    font-size: 22px;
}

.process-modal .modal-header .btn-close {
    position: absolute;
    right: 15px;
    top: 15px;
    margin: 0 !important;
    padding: 0;
    box-shadow: none;
    opacity: 0.6;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.process-modal .modal-body {
    padding: 50px;
}

.process-modal .modal-body .card {
    border-color: #e5e5e5;
}

.process-modal .modal-body .card-header {
    color: #ffffff;
    font-weight: 500;
    text-align: center;
    border: 0;
    padding: 15px;
    background: #4580c2;
}

.process-modal .modal-body .card-body {
    padding: 25px;
}

.process-modal .modal-body .card-body .payBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.process-modal .modal-body .card-body .payBox .boxInner {
    color: #797979;
    font-size: 14px;
    text-align: center;
}

.process-modal .modal-body .card-body .payBox .boxInner span {
    display: block;
    color: #1c1c1c;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding: 5px 20px;
    margin-top: 15px;
    border-radius: 6px;
    box-shadow: 0px 3px 15px -2px rgba(89, 157, 242, 0.8);
}

.process-modal .modal-body .card-body .payBox .vLine {
    background: #d8d8d8;
    width: 1px;
    height: 70px;
}

.process-modal .modal-body .redBox {
    color: #ff0000;
    font-weight: 500;
    padding: 10px;
    margin: 30px 0px;
    text-align: center;
    border-radius: 6px;
    background: #ffe6e6;
}

.process-modal .modal-footer {
    border: 0;
    justify-content: space-between;
    padding: 0px 50px 50px 50px;
}

.process-modal .modal-footer .btnOne {
    color: #ffffff;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin: 0;
    padding: 20px 45px;
    border: 0;
    border-radius: 0;
    background: linear-gradient(270deg, rgba(89, 157, 242, 1) 0%, rgba(85, 206, 186, 1) 100%);
}

.process-modal .modal-footer .btnOne.trans {
    color: #599df2;
    background: transparent;
}

.item-modal .modal-dialog {
    max-width: 600px;
}

.item-modal.tab-pills .modal-dialog {
    max-width: 730px;
}

.item-modal .modal-content {
    border: 0;
}

.item-modal .modal-header {
    justify-content: center;
    border: 0;
    padding-bottom: 0;
}

.item-modal .modal-header .modal-title {
    font-size: 22px;
    color: #313131;
    text-align: center;
}

.item-modal .modal-header .modal-title p {
    font-size: 18px;
    margin-top: 5px;
}

.item-modal .modal-header .btn-close {
    position: absolute;
    right: 15px;
    top: 15px;
    margin: 0 !important;
    padding: 8px;
    box-shadow: none;
    opacity: 0.5;
    border: 1px solid #a2a2a2;
    border-radius: 50px;
    font-size: 10px;
}

.item-modal .modal-body {
    padding: 0px 50px 40px;
}

.item-modal .modal-body hr {
    margin: 45px -20px 0px;
    border: 0px;
    border-top: 1px solid #f4f4f4;
    opacity: 1;
}

.item-modal .modal-body h6 {
    color: #313131;
    margin-top: 40px;
}

.item-modal .modal-body p {
    display: flex;
    align-items: center;
    color: #313131;
    margin: 20px 0px 0px;
}

.item-modal .modal-body .greenBox {
    display: flex;
    align-items: center;
    color: #313131;
    padding: 10px 20px;
    margin: 20px 0px 0px;
    border-radius: 6px;
    background: #e5faf3;
}

.item-modal .modal-body .greenBox span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
    color: #599df2;
    width: 20px;
    height: 20px;
    padding: 10px;
    border: 2px solid #599df2;
    border-radius: 50px;
    margin-right: 20px;
}

.item-modal .modal-body .grayBox {
    color: #313131;
    padding: 30px;
    text-align: center;
    border-radius: 20px;
    background: #f4f4f4;
}

.item-modal .modal-body .grayBox h6 {
    margin-bottom: 8px;
}

.item-modal .modal-body .grayBox p {
    display: block;
    margin: 0;
}

.item-modal .modal-body .grayBox hr {
    margin: 25px 0px;
    border: 0px;
    border-top: 1px solid #d8d8d8;
    opacity: 1;
}

.item-modal .modal-body .planBox {
    color: #313131;
    padding: 10px 20px;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
}

.item-modal .modal-body .planBox table {
    color: #797979;
    font-size: 15px;
    margin: 0;
    border: none;
    border-color: transparent;
    white-space: nowrap;
}

.item-modal .modal-body .planBox table th {
    color: #313131;
}

.item-modal .modal-body input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    border: none;
    box-shadow: none;
    margin: 0;
    margin-right: 12px;
}

.item-modal .modal-body input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    border: 2px solid #c6c6c6;
    border-radius: 3px;
    background-color: #ffffff;
}

.item-modal .modal-body input[type='checkbox']:checked:before {
    border-color: #599df2;
    background-color: #599df2;
}

.item-modal .modal-body input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 4px;
    height: 8px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 3px;
    left: 6px;
}

.item-modal .modal-body input[type='date']::-webkit-calendar-picker-indicator {
    filter: invert(52%) sepia(87%) saturate(837%) hue-rotate(189deg) brightness(97%) contrast(94%);
    -webkit-filter: invert(52%) sepia(87%) saturate(837%) hue-rotate(189deg) brightness(97%) contrast(94%);
    font-size: 22px;
    margin-top: -20px;
}

.item-modal .modal-body input[type='time']::-webkit-calendar-picker-indicator {
    filter: invert(52%) sepia(87%) saturate(837%) hue-rotate(189deg) brightness(97%) contrast(94%);
    -webkit-filter: invert(52%) sepia(87%) saturate(837%) hue-rotate(189deg) brightness(97%) contrast(94%);
    font-size: 22px;
    margin-top: -20px;
}

.item-modal .modal-body .checkBox .switch {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 45px;
    margin: 0px;
}

.item-modal .modal-body .checkBox .switch input {
    display: none;
}

.item-modal .modal-body .checkBox .switch .slider {
    background-color: #f2f2f2;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.item-modal .modal-body .checkBox .switch .slider:before {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 2px;
    height: 16px;
    width: 16px;
    background: #ffffff;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.item-modal .modal-body .checkBox .switch input:checked+.slider {
    background: linear-gradient(270deg, rgba(89, 157, 242, 1) 0%, rgba(85, 206, 186, 1) 100%);
}

.item-modal .modal-body .checkBox .switch input:checked+.slider:before {
    transform: translateX(25px);
}

.item-modal .modal-body .checkBox .switch .slider.round {
    border-radius: 35px;
}

.item-modal .modal-body .checkBox .switch .slider.round:before {
    border-radius: 50%;
}

.item-modal .modal-body .divBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
}

.item-modal .modal-body .divBox h6,
.item-modal .modal-body .divBox p {
    margin: 0;
}

.item-modal .modal-body .uplodBtn {
    color: #ffffff;
    font-weight: 500;
    margin: 0 auto;
    padding: 8px 20px;
    border: 0;
    border-radius: 50px;
    background: linear-gradient(270deg, rgba(89, 157, 242, 1) 0%, rgba(85, 206, 186, 1) 100%);
}

.item-modal .modal-body .uplodBox {
    background: #ffffff;
    border: 1px dashed #707070;
    padding: 20px 30px;
    margin: 30px;
    border-radius: 30px;
}

.item-modal .modal-body .uplodBox h5 {
    display: flex;
    align-items: center;
    color: #313131;
    font-size: 20px;
    margin: 0;
}

.item-modal .modal-body .uplodBox p {
    color: #c6c6c6;
    margin-top: 5px;
    font-size: 14px;
}

.item-modal .modal-body .uplodBox p span {
    color: #0071ff;
}

.item-modal .modal-body .sampleBox {
    display: flex;
    align-items: center;
    color: #313131;
    font-weight: 500;
    margin: 0;
}

.item-modal .modal-body .input-group {
    margin-top: 40px;
}

.item-modal .modal-body .input-group input,
.item-modal .modal-body .input-group select,
.item-modal .modal-body .input-group textarea {
    border: 0;
    border-radius: 0;
    color: #313131;
    padding: 35px 25px 10px 25px;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    resize: none;
}

.item-modal .modal-body .input-group span {
    position: absolute;
    top: 15%;
    left: 26px;
    font-size: 12px;
    color: #a6a6a6;
    text-transform: uppercase;
    z-index: 9;
}

.item-modal .modal-body .input-group span.txtArea {
    top: 8%;
}

.item-modal .modal-body .input-group b {
    position: absolute;
    top: 50%;
    right: 25px;
    font-size: 14px;
    font-weight: 500;
    color: #313131;
    transform: translateY(-50%);
    z-index: 9;
}

.item-modal .modal-body .nav-pills .nav-item {
    margin-right: 20px;
}

.item-modal .modal-body .nav-pills .nav-item:last-child {
    margin-right: 0px;
}

.item-modal .modal-body .nav-pills .nav-link {
    color: #afafaf;
    font-size: 14px;
    border-radius: 50px;
    background: #f4f4f4;
    cursor: pointer;
}

.item-modal .modal-body .nav-pills .nav-link.active {
    color: #ffffff;
    background: #0071ff;
}

.item-modal .modal-body .tab-content {
    padding: 30px 30px 0px;
}

.item-modal .modal-footer {
    border: 0;
    padding: 0px 50px 50px 50px;
}

.item-modal .modal-body .input-group .btnOne,
.item-modal .modal-footer .btnOne {
    flex: 1;
    color: #ffffff;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin: 0;
    padding: 20px;
    border: 0;
    border-radius: 0;
    background: linear-gradient(270deg, rgba(89, 157, 242, 1) 0%, rgba(85, 206, 186, 1) 100%);
}

.item-modal .modal-body .input-group .btnOne.trans,
.item-modal .modal-footer .btnOne.trans {
    color: #599df2;
    background: #f4f4f4;
    margin-right: 10px;
}

@media only screen and (max-width: 1400px) {
    .innerMain .linkBox.payMeth a {
        padding: 12px 30px;
    }
}

@media only screen and (max-width: 1700px) {
    .innerMain .boxOne {
        flex-direction: column;
    }

    .innerMain .titleBox h5 {
        margin: 10px 0px 15px;
    }

    .innerMain .chartSale {
        width: 540px;
        height: 260px;
    }

    .innerMain .chartInsight {
        height: 284px;
    }

    .innerMain .chartTraffic {
        height: 371px;
    }

    .innerMain .settingSideblock .card-body,
    .innerMain .settingBlock .card-body {
        padding: 0px;
    }
}

@media only screen and (max-width: 767px) {
    .header .input-group {
        width: 100%;
        margin: 10px 0px 15px;
    }

    .header-nav .nav-item {
        margin: 0px 18px;
    }

    .breadcrumb-item.active {
        margin-bottom: 30px;
    }

    .login-area .loginBox {
        flex-direction: column-reverse;
    }

    .login-area .loginBox .boxOne {
        padding: 30px;
        margin-top: 30px;
        margin-bottom: 10px;
        width: 100%;
    }

    .login-area .loginBox .boxTwo {
        padding: 30px;
        margin: 0;
        margin-top: 10px;
        width: 100%;
    }

    .login-area .loginBox .boxOne .innerBox .iconBox {
        height: 50px;
    }

    .login-area .loginBox .boxOne .innerBox .iconBox img {
        width: 25px;
    }

    .innerMain .filterBox h5 {
        margin-bottom: 20px;
    }

    .innerMain .filterBox .input-group .divBox {
        flex-wrap: wrap;
    }

    .innerMain .filterBox .input-group .divBox h6 {
        display: none;
    }

    .innerMain .filterBox .input-group .divBox .btnBox {
        margin: 0;
        margin-top: 20px;
        justify-content: center;
    }

    .innerMain .boxShadow {
        padding: 10px 10px 15px;
    }

    .innerMain .dateBox select {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 15px;
    }

    .innerMain .dateBox input {
        width: 100%;
        margin-bottom: 15px;
    }

    .innerMain .dateBox h6 {
        display: none;
    }

    .innerMain .titleBox h5 {
        margin-bottom: 25px;
    }

    .innerMain .chartSale {
        height: 300px;
        width: 100%;
    }

    .innerMain .chartInsight {
        height: 250px;
    }

    .innerMain .chartTraffic {
        height: 100%;
    }

    .innerMain .linkBox a {
        display: block;
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .innerMain .payLinks {
        display: block;
    }

    .innerMain .payLinks .linkBtn {
        margin-left: 0;
        margin-bottom: 10px;
    }

    .innerMain .payLinks .filterBox {
        margin-left: 0px;
        margin-top: 0px;
    }

    .innerMain .invoiceBlock .invoiceHead {
        flex-direction: column;
    }

    .innerMain .invoiceBlock .invoiceHead .headLeft {
        flex-direction: column;
        margin-bottom: 30px;
    }

    .innerMain .invoiceBlock .invoiceHead .headLeft .textBox {
        margin: 0;
        margin-top: 20px;
        text-align: center;
    }

    .innerMain .reportBlock {
        padding: 0px 0px 10px 0px;
        margin: 0px 0px 40px 0px;
        border: 0;
        border-bottom: 2px solid #f4f4f4;
    }

    .innerMain .reportBlock .reportBox {
        margin: 0px 0px 40px 0px;
    }

    .innerMain .settingBlock .profileBox {
        flex-direction: column;
        align-items: flex-start;
    }

    .innerMain .settingBlock .profileBox .textBox {
        margin-left: 0px;
        margin-top: 15px;
    }

    .process-modal .modal-body {
        padding: 30px;
    }

    .process-modal .modal-body .card-body .payBox {
        flex-direction: column;
    }

    .process-modal .modal-body .card-body .payBox .vLine {
        width: 100%;
        height: 1px;
        margin: 30px 0px 20px;
    }

    .process-modal .modal-footer {
        padding: 0px 30px 30px 30px;
    }

    .process-modal .modal-footer .btnOne {
        display: block;
        width: 100%;
    }

    .item-modal .modal-header .modal-title p {
        font-size: 15px;
    }

    .item-modal .modal-body {
        padding: 10px 30px 30px 30px;
    }

    .item-modal .modal-body .card-body .payBox {
        flex-direction: column;
    }

    .item-modal .modal-body .card-body .payBox .vLine {
        width: 100%;
        height: 1px;
        margin: 30px 0px 20px;
    }

    .item-modal .modal-body .uplodBox {
        margin: 30px 0px;
    }

    .item-modal .modal-body .uplodBox h5 {
        flex-direction: column;
        margin-bottom: 15px;
    }

    .item-modal .modal-body .uplodBox h5 .uplodBtn {
        margin-top: 15px;
        width: 100%;
    }

    .item-modal .modal-body .sampleBox {
        flex-direction: column;
    }

    .item-modal .modal-body .sampleBox .uplodBtn {
        margin-top: 10px;
        width: 100%;
    }

    .item-modal .modal-body .planBox {
        margin-top: 15px;
    }

    .item-modal .modal-body .nav-pills .nav-item {
        margin: 5px;
    }

    .item-modal .modal-body .nav-pills .nav-item:last-child {
        margin-right: 5px;
    }

    .item-modal .modal-body .tab-content {
        padding: 0px;
    }

    .item-modal .modal-footer {
        padding: 0px 30px 30px 30px;
    }

    .item-modal .modal-body .input-group .btnOne,
    .item-modal .modal-footer .btnOne {
        width: 100%;
        flex: auto;
    }

    .item-modal .modal-body .input-group .btnOne.trans,
    .item-modal .modal-footer .btnOne.trans {
        margin-bottom: 10px;
        margin-right: 0px;
    }
}

.ShowLoaderPage {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 999;
    text-align: center;
    padding-top: 20%;
    background: #fffffff7;
}

.ShowLoaderPage img {
    max-width: 60px;
}

.text-purple {
    color: #7a3694;
}

.LoadingAnimation img {
    max-width: 95px;
}

.LoadingAnimation {
    animation: upDown 2s linear infinite;
    text-align: center;
}

@keyframes upDown {
    0% {
        padding-top: 0;
    }

    50% {
        padding-top: 50px;
    }

    100% {
        padding-top: -50px;
    }
}

.hideEmpty:empty {
    display: none !important;
}

.PhoneInputInput {
    padding: 20px !important;
    border: 0;
    outline: none;
}

.PhoneInputInputCont {
    border: 0;
    border-radius: 0;
    color: #313131;
    padding: 10px 25px 10px 25px;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    resize: none;
    margin-top: 40px;
}

.PhoneInputInputCont label {
    color: #a6a6a6;
}

.payPublic a {
    text-decoration: none;
}

.payPublic label {
    color: gray;
    display: block;
}

.payPublic b {
    color: black;
    display: block;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.clearBoth {
    clear: both;
}

.hoverShowContItem {
    display: none;
}

.hoverShowCont:hover .hoverShowContItem {
    display: block;
}

.ShowOnHoverCont:hover .ShowOnHover {
    opacity: 1;
}

.ShowOnHover {
    opacity: 0;
}

.smallText {
    font-size: 0.5rem;
}

.noUnderLine {
    text-decoration: none;
}

.qrCodeCont {
    background: linear-gradient(0deg, rgb(89, 157, 242) 0%, rgb(85, 206, 186) 100%);
}

.searchAjaxCont {
    position: relative;
}

.searchAjaxData {
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    max-height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
    background: #fff;
    z-index: 999998;
    border: 1px solid #f5f5f5;
    padding: 10px 15px;
}

.searchAjaxDataClose {
    position: absolute;
    top: 0px;
    right: 5px;
    z-index: 999999;
    font-size: 0.8rem;
    font-weight: 600;
    color: #cc0000;
}

.img-100 {
    max-width: 100%;
}

.text-left {
    text-align: left;
}

.userDetailList li {
    border: none;
    height: 55px;
    display: flex;
    align-items: center;
    border-radius: 0px 15px 15px 0px !important;
    margin-bottom: 5px;
}

.userDetailList li:last-child {
    margin-bottom: 0px;
}

.userDetailList li:hover,
.userDetailList li.active {
    background: #eceefe;
    border-left: 5px solid #5367f9;
    color: #000000;
    cursor: pointer;
}

.userDetailList li {
    border-left: 5px solid #fff;
}

.cardDataUserDetail {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fw-semibold {
    font-size: smaller !important;
}

.ReportsSmallCards {
    padding: 10px;
    background: #f7f7f7;
    margin-top: 10px;
    border-radius: 14px;
}

.reportCarD {
    border: none;
    border-radius: 14px;
}

.reportHeader {
    margin-top: 26px;
    background-color: whitesmoke;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
}

.reportLinks {
    height: 77%;
    width: 100%;
}

@media (max-width: 1200px) {
    .reportLinks {
        height: max-content;
        margin-bottom: 20px;
    }
}

.reportInsightCard p {
    margin: 2px;
    font-size: small;
}

.table-responsive {
    max-height: 80vh;
    overflow: auto;
}

.NoMaxHeightTable .table-responsive {
    max-height: unset;
}

.categoryTapDashboard {
    width: 170px;
    font-size: small;
    background-color: #eceeff;
    border-radius: 12px;
    position: absolute;
    padding: 10px 0 30px;
    z-index: 3;
    top: -40px;
    font-weight: 600;
    text-align: center;
    left: 13px;
}

.iconSpaceDashboard {
    background: linear-gradient(128deg, rgba(42, 57, 255, 1) 0%, rgba(255, 93, 235, 1) 100%);
    height: 50px;
    width: 50px;
    font-size: xx-large;
    color: white;
    padding-right: 5px;
}

.boxshaDow {
    border: 0px;
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    margin: 0px;
    padding: 0px;
    // background-color: #0071ff;
}

.trBorder {
    border-top: 2px solid #f5f5f5;
}

.trBorder:hover {
    border: 2px solid #4580c2;
}

.place::-webkit-inner-spin-button,
.place::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.place:hover::-webkit-inner-spin-button,
.place:hover::-webkit-outer-spin-button {
    display: none;
}

.rGBox {
    background-color: rgb(218 255 249);
    height: 225px;
    width: 220px;
    border: 1px solid #ebedef;
    border-radius: 12px;
    position: relative;
}

.RGimg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
}

.RGprofile {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    border-radius: 12px;
    background: #f1efee;
}

.iconSpaceRG {
    background: linear-gradient(128deg, rgba(42, 57, 255, 1) 0%, rgba(255, 93, 235, 1) 100%);
    height: 30px;
    width: 30px;
    font-size: x-large;
    color: white;
    padding-right: 5px;
}

.optionRG {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 25px;
    height: 25px;
    color: white;
    background-color: #ebedef78;
    display: flex;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
}

.PhotoInfovisible {
    display: block;
    position: absolute;
    top: 25%;
    left: 40%;
}

@media (max-width: 800px) {
    .PhotoInfovisible {
        top: 25%;
        left: 20%;
    }
}

@media (max-width: 550px) {
    .PhotoInfovisible {
        top: 50%;
        left: 5%;
    }
}

.hiddenRG {
    display: none;
}

.bluringBack {
    filter: blur(5px);
    position: relative;
}

.RoundDiv {
    padding: 10px;
    border: 1px solid #ebedef;
    border-radius: 12px;
    margin: 10px;
}

.RoundDiv ul {
    list-style-type: none;
}

.report-modal .modal-content {
    border-radius: 20px;
}

.report-modal .modal-header {
    justify-content: center;
    border: none;
    padding-bottom: 0px;
}

.report-modal .modal-header .btn-close {
    position: absolute;
    right: 30px;
    font-size: 10px;
    padding: 5px;
    border: 2px solid #8D8D8D;
    border-radius: 50%;
    opacity: 1;
}

.report-modal .modal-title {
    color: #000000;
}

.report-modal .modal-body img {
    max-width: 100%;
    border-radius: 30px;
    padding: 10px;
}

.report-modal .modal-body .mainDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    border: 1px solid #ebedef;
    border-radius: 20px;
    margin: 10px;
}

.report-modal .modal-body .mainDiv .userDiv {
    display: flex;
    align-items: center;
}

.report-modal .modal-body .mainDiv .userDiv img {
    width: 50px;
    height: 50px;
    padding: 0;
    margin-right: 10px;
    border-radius: 50%;
}

.report-modal .modal-body .mainDiv .userDiv h6 {
    color: #000000;
    margin: 0px;
}

.report-modal .modal-body .mainDiv .userDiv span {
    font-size: 14px;
    color: #5367F9;
}

.report-modal .modal-body .mainDiv .btnDiv .btn {
    color: #5367F9;
    border: 1px solid #ebedef;
    border-radius: 30px;
    padding: 8px 20px;
}

.report-modal .modal-body .mainDiv .btnDiv .btn:hover {
    color: #ffffff;
    background: #5367F9;
}

.report-modal .modal-body .mainDiv .table {
    margin: 0;
}

.report-modal .modal-body .mainDiv .table td {
    border: 0;
}

.report-modal .modal-body .mainDiv .table td b {
    font-weight: 500;
}

.report-modal .modal-footer {
    border: 0;
    padding: 0px 22px 12px;
    justify-content: center;
}

.report-modal .modal-footer .btn.skip {
    color: #000000;
    border: 2px solid #5367F9;
    border-radius: 12px;
    padding: 8px 20px;
    width: 100%;
    font-weight: 500;
}

.report-modal .modal-footer .btn.skip:hover {
    color: #ffffff;
    background: #5367F9;
}

.report-modal .modal-footer .btn.remove {
    color: #ffffff;
    background: #FF0000;
    border-radius: 12px;
    padding: 8px 20px;
    width: 100%;
    font-weight: 500;
    margin-top: 10px;
}

.modal-backdrop.show {
    opacity: 0.9;
}

.userBox {
    display: flex;
    align-items: center;
}

.userBox img {
    width: 90px;
    height: 90px;
    margin-right: 20px;
    border-radius: 50%;
}

.userBox .userDiv h5 {
    color: #000000;
    margin: 0px;
}

.userBox .userDiv small {
    display: block;
    font-size: 15px;
    color: #5367F9;
}

.userBox .userDiv .badge {
    color: #0A9F43;
    background: #E3FFEE;
    border: 1px solid #7CD6AC;
    border-radius: 50px;
    padding: 5px 15px;
    margin-top: 5px;
}

.user-report-area {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    background: #F5F5F5;
    border: 1px solid #EDEDED;
    padding: 30px;
    border-radius: 20px;
}

.user-report-area .boxMain {
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #EDEDED;
    padding: 20px;
    border-radius: 20px;
}

.user-report-area .boxMain .iconBox {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2b6bf1;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.user-report-area .boxMain .textBox {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}

.user-report-area .boxMain .textBox small {
    color: #6d6d6d;
}

.user-gallery {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.user-gallery img {
    width: 170px;
    border-radius: 10px;
}

.profileBox {
    padding: 0px 10px 10px 0px;
    margin-bottom: 30px;
    border-bottom: 1px solid #e9e9e9;
}

.profileBox small {
    color: #848484;
    display: block;
}

.profileBox b {
    font-weight: 500;
    color: #000000;
}

.profileBox p {
    color: #000000;
    margin-bottom: 0px;
}

.activityBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #e9e9e9;
}

.activityBox .box-one b {
    display: block;
    color: #000000;
    font-weight: 500;
}

.activityBox .box-one b span {
    color: #5367F9;
}

.activityBox .box-one small {
    color: #000000;
}

.activityBox .box-one small span {
    color: #5367F9;
}

.activityBox .box-two small {
    color: #717171;
}

.activityBox .box-two b {
    display: block;
    color: #000000;
    font-weight: 500;
}

.activityBox .box-two img {
    width: 80px;
    margin-bottom: 5px;
    border-radius: 10px;
}

.activity .accordion-item {
    border: none;
    margin-bottom: 10px;
}

.activity .accordion-button {
    background: #eaecfc;
    border-radius: 20px !important;
    box-shadow: none;
    font-weight: 500;
    padding: 15px 30px;
}

.activity .accordion-body {
    padding: 30px;
}

.activity-modal .modal-lg {
    width: 700px;
}

.activity-modal .modal-xl {
    width: 950px;
}

.activity-modal .modal-content {
    border-radius: 20px;
}

.activity-modal .modal-header {
    border: none;
    padding: 25px 25px 20px 25px;
}

.activity-modal .modal-header .btn-close {
    font-size: 10px;
    padding: 5px;
    margin: 0px !important;
    border: 2px solid #8D8D8D;
    border-radius: 50%;
    opacity: 1;
}

.activity-modal .modal-title {
    color: #000000;
}

.activity-modal .modal-body {
    padding: 25px;
    padding-bottom: 0px;
}

.activity-modal .modal-body .twoCol {
    display: flex;
    gap: 20px;
}

.activity-modal .modal-body .mainDiv {
    flex: 1;
    position: relative;
    padding: 30px 20px 15px;
    border: 1px solid #ebedef;
    border-radius: 20px;
    margin-bottom: 40px;
}

.activity-modal .modal-body .mainDiv small {
    position: absolute;
    top: -16px;
    left: 20px;
    background: #ffffff;
    color: #000000;
    font-weight: 500;
    padding: 5px 10px;
    border: 1px solid #ebedef;
    border-radius: 20px;
}

.activity-modal .modal-body .mainDiv p {
    color: #000000;
    margin-bottom: 0px;
}

.activity-modal .modal-body .mainDiv img {
    max-width: 100%;
    border-radius: 15px;
}

.activity-modal .modal-body .mainDiv .userDiv {
    display: flex;
    align-items: center;
}

.activity-modal .modal-body .mainDiv .userDiv img {
    width: 70px;
    height: 70px;
    padding: 0;
    margin-right: 10px;
    border-radius: 10px;
}

.activity-modal .modal-body .mainDiv .userDiv h6 {
    color: #000000;
    margin: 0px;
}

.activity-modal .modal-body .mainDiv .userDiv span {
    font-size: 14px;
    color: #5367F9;
}

.activity-modal .modal-body .mainDiv .table {
    margin: 0;
}

.activity-modal .modal-body .mainDiv .table td {
    border: 0;
    padding: 1px 0px;
}

.activity-modal .modal-body .mainDiv .table td b {
    color: #000000;
    font-weight: 500;
}

.activity-modal .modal-body .editBox {
    display: flex;
    gap: 30px;
    padding: 30px;
    background: #F5F5F5;
    border-radius: 20px;
}

.activity-modal .modal-body .editBox .mainBox.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-self: flex-start;
}

.activity-modal .modal-body .editBox .mainBox {
    flex: 1;
}

.activity-modal .modal-body .editBox .mainBox h5 {
    color: #000000;
    font-size: 18px;
    margin-bottom: 25px;
}

.activity-modal .modal-body .editBox .mainBox .innerBox {
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
}

.activity-modal .modal-body .editBox .mainBox .innerBox h6 {
    color: #000000;
    font-size: 16px;
    margin-bottom: 20px;
}

.activity-modal .modal-body .editBox .mainBox .innerBox .placeBox {
    display: flex;
    align-items: center;
    gap: 10px;
}

.activity-modal .modal-body .editBox .mainBox .innerBox .placeBox .textBox {
    display: flex;
    gap: 20px;
}

.activity-modal .modal-body .editBox .mainBox .innerBox .placeBox .textBox .imgBox {
    flex: 1 0 40%;
}

.activity-modal .modal-body .editBox .mainBox .innerBox .placeBox .textBox .imgBox img {
    padding: 3px;
    border: 2px solid #5367F9;
    border-radius: 8px;
}

.activity-modal .modal-body .editBox .mainBox .innerBox .placeBox .textBox b {
    display: block;
    color: #000000;
    font-weight: 500;
    margin-bottom: 5px;
}

.activity-modal .modal-body .editBox .mainBox .innerBox .placeBox .textBox p {
    font-size: 14px;
    margin-bottom: 0px;
}

.activity-modal .modal-body .editBox .mainBox .innerBox .selectBox {
    margin-top: 25px;
}

.activity-modal .modal-body .editBox .mainBox .innerBox .selectBox .form-label {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #000000;
    font-weight: 500;
}

.activity-modal .modal-body .editBox .mainBox .innerBox .selectBox .form-label i {
    color: #9A9A9A;
    font-size: 18px;
    cursor: pointer;
}

.activity-modal .modal-body .editBox .mainBox .innerBox .selectBox select {
    box-shadow: none;
}

.activity-modal .modal-footer {
    border: 0;
    padding: 25px;
    justify-content: center;
}

.activity-modal .modal-footer .btn {
    color: #5367F9;
    border: 2px solid #5367F9;
    border-radius: 12px;
    padding: 10px 30px;
    margin: 0px 10px;
    font-weight: 500;
}

.activity-modal .modal-footer .btn:hover {
    color: #ffffff;
    background: #5367F9;
}

.activity-modal .modal-footer .btn.done {
    color: #ffffff;
    background: #5367F9;
}

.activity-modal .modal-footer .btn.cancel {
    color: #000000;
    background: #EDEDED;
    border-color: #EDEDED;
}

.transDetail {
    display: flex;
    gap: 20px;
}

.transDetail .detailBox {
    flex: 1;
    color: #000000;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #EDEDED;
    border-radius: 10px;
}

.transDetail .detailBox.green {
    background: #ECFCF1;
    border: 1px solid #AEEFB9;
}

.transDetail .detailBox h5 {
    color: #5367F9;
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: 10px;
}

.transDetail .detailBox .innerBox {
    display: flex;
    margin-bottom: 5px;
}

.transDetail .detailBox .innerBox span {
    flex: 0 1 33%;
}

.transDetail .detailBox .innerBox b.blue {
    color: #5367F9;
}

.transDetail .detailBox .innerBox b.green {
    color: #09BF48;
}

.request {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.request b {
    color: #000000;
    font-weight: 500;
}

.request h4 {
    color: #000000;
    font-weight: 700;
    margin-bottom: 0px;
}

.card-title {
    color: #000000;
}

.card-title small {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    display: block;
    margin-top: 5px;
}

.taskBlock {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.taskBlock .innerBox {
    flex: 1 0 25%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-radius: 10px;
    background: #F7F7F7;
}

.taskBlock .innerBox .textBox small {
    display: block;
    color: #3E3E3E;
}

.taskBlock .innerBox .textBox b {
    color: #5367F9;
}

.taskBlock .innerBox .btnBox .btn {
    color: #5367F9;
    padding: 10px 20px;
    border-radius: 30px;
    background: #d7dbf8;
}

.campBlock {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 20px;
}

.campBlock.two .innerBox {
    flex: 1 0 20%;
}

.campBlock .innerBox {
    flex: 1 0 25%;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #F7F7F7;
    background: #F7F7F7;
}

.campBlock .innerBox.active {
    background: #ffffff;
    border: 1px solid #5367F9;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.campBlock .innerBox.active.bgc {
    background: #5367F9;
}

.campBlock .innerBox.active.bgc h4,
.campBlock .innerBox.active.bgc small {
    color: #ffffff;
}

.campBlock .innerBox h4 {
    font-size: 16px;
    font-weight: 700;
    color: #000000;
}

.campBlock .innerBox span {
    font-size: 12px;
    color: #ffffff;
    background: #00CC6E;
    padding: 4px 20px;
    border-radius: 50px;
    cursor: pointer;
}

.campStatus {
    display: flex;
    align-items: center;
    gap: 10px;
}

.campStatus .imgLink {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EDEDED;
}

.campStatusBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fafafa;
    border: 1px solid #EDEDED;
    border-radius: 8px;
    padding: 8px 15px;
    margin-bottom: 15px;
}

.campStatusBox.none {
    background: transparent;
    border: 1px solid transparent;
    padding: 1px 15px;
    margin-bottom: 0px;
}

.campStatusBox b,
.campStatusBox.none b.big {
    color: #000000;
    font-weight: 700;
}

.campStatusBox.none b {
    color: #585858;
    font-weight: 400;
}

.campStatusBox small {
    color: #000000;
    font-weight: 500;
}

.softCorner {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
}

.softCorner div {
    flex: 1 0 18%;
}
// for the Two factor CSS
.OptFromSection {
    padding: 10px;
    border: 1px solid #dedede;
    margin-bottom: 10px;
    background: #fff;
}